import React, { useContext, useEffect, useState } from 'react';

import GlobalContext from '../../../context/global-context';
import CustomToast from '../../global/customToast/customToast';
import Heading from '../general/heading/heading';

import ChangePassword from './changePassword';
import UpdateAccount from './updateAccount';

import './home.scss';

const Home: React.FC = () => {
  const { i18n } = useContext(GlobalContext);

  const [updateAccountSuccess, setUpdateAccountSuccess] = useState<
    boolean | null
  >(null);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    if (updateAccountSuccess && changePasswordSuccess)
      setChangePasswordSuccess(false);
  }, [updateAccountSuccess]);
  useEffect(() => {
    if (updateAccountSuccess && changePasswordSuccess)
      setUpdateAccountSuccess(false);
  }, [changePasswordSuccess]);

  return (
    <div className="account-wrapper">
      <Heading label={i18n?.t('my_account')} />

      <div className="account-form-wrapper form-wrapper">
        <UpdateAccount setSuccess={setUpdateAccountSuccess} />
        <ChangePassword setSuccess={setChangePasswordSuccess} />
      </div>

      <CustomToast
        toast={updateAccountSuccess}
        setToast={setUpdateAccountSuccess}
      >
        <span>{i18n?.t('form.success.personal_details')}</span>
      </CustomToast>

      <CustomToast
        toast={changePasswordSuccess}
        setToast={setChangePasswordSuccess}
      >
        <span>{i18n?.t('form.success.password')}</span>
      </CustomToast>
    </div>
  );
};

export default Home;
