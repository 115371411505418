export const ROUTE_REGEX = '^\\/([a-z]{1,})(\\/.*)?';

export const PATH_WIHTOUT_QS_REGEX = '^((\\/[^\\/?]{1,})*)\\/?';

export const PASSWORD_ALL_REQUIREMENTS_VALIDATOR =
  /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%*&+=])/g;

export const ATLEAST_ONE_NUMBER = /(?=.*\d)/g;

export const ATLEAST_ONE_LOWER_CASE = /(?=.*[a-z])/g;

export const ATLEAST_ONE_UPPER_CASE = /(?=.*[A-Z])/g;

export const ATLEAST_ONE_SPECIAL_CHAR = /(?=.*[!@#$%*&+=])/g;

// does not accept special characters - letters and numbers only [a-zA-Z0-9]
export const ATLEAST_8_CHARACTERS = /(?=.{8,}$)/g;

export const EMAIL_VALIDATION =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
