import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';

import GlobalContext from '../../../context/global-context';
import { ReactComponent as HidePWSVG } from '../../../images/password-reveal-hide.svg';
import { ReactComponent as ShowPwSVG } from '../../../images/password-reveal-show.svg';
import { ReactComponent as WarningSVG } from '../../../images/warning_circle_red.svg';
import { NewPasswordInputState } from '../../../types/auth';
import { isBrowser } from '../../../utils/browser';
import { PASSWORD_ALL_REQUIREMENTS_VALIDATOR } from '../../../utils/regexes';
import NewPasswordInput from '../../auth/newPasswordInput/newPasswordInput';
import Loading from '../../global/loading/loading';

const ChangePassword: React.FC<{ setSuccess: any }> = ({ setSuccess }) => {
  const { i18n } = useContext(GlobalContext);

  const [currentPassword, setCurrentPassword] = useState({
    value: '',
    isValid: false,
    isTouched: false,
  });

  const [newPasswordInput, setNewPasswordInput] =
    useState<NewPasswordInputState>({
      value: '',
      isValid: false,
    });
  const [confirmationInput, setConfirmationInput] =
    useState<NewPasswordInputState>({ value: '', isValid: false });
  const [error, setError] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [invalidCurrentPwdError, setInvalidCurrentPwdError] = useState(false);
  const [changePwdError, setChangePwdError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const resetAllError = () => {
    setError(false);
    setInvalidCurrentPwdError(false);
    setChangePwdError(false);
  };

  const handleChange = (value: string, setFn: any, field: string) => {
    let isValid = false;
    if (field === 'currentPassword') isValid = value.length > 0 ? true : false;
    setFn({ value, isValid });
  };

  const handleBlur = (setFn: any) => {
    setFn((prevValue: any) => {
      return { ...prevValue, isTouched: true };
    });
  };

  useEffect(() => {
    if (newPasswordInput)
      setNewPasswordInput((prevValue) => {
        return {
          ...prevValue,
          isValid: PASSWORD_ALL_REQUIREMENTS_VALIDATOR.test(
            newPasswordInput.value
          ),
        };
      });
  }, [newPasswordInput.value]);

  useEffect(() => {
    if (confirmationInput) {
      setConfirmationInput((prevValue) => {
        return {
          ...prevValue,
          isValid: newPasswordInput.value === confirmationInput.value,
        };
      });
    }
  }, [newPasswordInput.value, confirmationInput.value]);

  const checkValidityOnSubmit = (): boolean => {
    return (
      currentPassword.isValid &&
      confirmationInput.isValid &&
      newPasswordInput.isValid
    );
  };

  const jwt = isBrowser ? window.localStorage.getItem('jwt') : null;

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // Already submitting
    if (submitting) {
      return;
    }

    resetAllError();

    if (!checkValidityOnSubmit()) return setError(true);
    if (checkValidityOnSubmit()) {
      try {
        setSubmitting(true);

        axios
          .put(
            `${process.env.GATSBY_STRAPI_URL}/api/users/me/account`,
            {
              password: newPasswordInput.value,
              currentPassword: currentPassword.value,
            },
            {
              headers: {
                Authorization: `Bearer ${jwt}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              resetAllError();
              setSuccess(true);
            }
          })
          .catch((err) => {
            const errorMsg = (
              err.response.data?.error?.message ?? ''
            ).toLowerCase();

            resetAllError();
            if (errorMsg.includes('invalid current password')) {
              setInvalidCurrentPwdError(true);
            } else {
              setChangePwdError(true);
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      } catch (err) {
        resetAllError();
        setChangePwdError(true);
        setSubmitting(false);
      }
    }
  };

  return (
    <div className="section-container">
      <h3>{i18n?.t('change_your_password')}</h3>
      <form className="account-change-form" onSubmit={handleSubmit}>
        <div className="input-row">
          <label>{i18n?.t('form.labels.verify_current_password')}</label>
          <div className="password-row">
            <input
              type={isPasswordVisible ? 'text' : 'password'}
              onChange={(e) => {
                handleChange(
                  e.target.value,
                  setCurrentPassword,
                  'currentPassword'
                );
              }}
              onBlur={() => {
                handleBlur(setCurrentPassword);
              }}
              className={
                !currentPassword.isValid && currentPassword.isTouched
                  ? 'red-border'
                  : ''
              }
            ></input>
            {isPasswordVisible ? (
              <HidePWSVG
                className="hide"
                onClick={() => {
                  setIsPasswordVisible((prevValue) => !prevValue);
                }}
              />
            ) : (
              <ShowPwSVG
                className="show"
                onClick={() => {
                  setIsPasswordVisible((prevValue) => !prevValue);
                }}
              />
            )}
          </div>
          {!currentPassword.isValid && currentPassword.isTouched && (
            <span className="warning">
              {i18n?.t('form.errors.required_current_password')}
            </span>
          )}
          {invalidCurrentPwdError && (
            <span className="warning">
              {i18n?.t('form.errors.invalid_current_password')}
            </span>
          )}
        </div>

        <div className="input-row">
          <NewPasswordInput
            setNewPasswordState={setNewPasswordInput}
            newPasswordState={newPasswordInput}
            label={i18n?.t('form.labels.new_password') ?? 'New password'}
            type="new"
          />
        </div>
        <div className="input-row">
          <NewPasswordInput
            setNewPasswordState={setConfirmationInput}
            newPasswordState={confirmationInput}
            label={
              i18n?.t('form.labels.confirm_password') ?? 'Confirm password'
            }
            type="confirmation"
          />
        </div>

        <span className="filled">
          <button
            className="submit-button btn btn-primary"
            type="submit"
            disabled={!checkValidityOnSubmit()}
          >
            {submitting && <Loading type="btn" />}
            {!submitting && i18n?.t('update')}
          </button>
        </span>
      </form>
      {error && (
        <div className="error-container">
          <div className="icon-container">
            <WarningSVG />
          </div>
          <p>{i18n?.t('form.errors.all_fields_and_password')}</p>
        </div>
      )}
      {changePwdError && (
        <div className="error-container">
          <div className="icon-container">
            <WarningSVG />
          </div>
          <p>{i18n?.t('form.errors.password_update')}</p>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
