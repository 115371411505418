import axios from 'axios';
import React, { useState, useContext } from 'react';

import GlobalContext from '../../../context/global-context';
import { ReactComponent as LockSVG } from '../../../images/lock.svg';
import { ReactComponent as WarningSVG } from '../../../images/warning_circle_red.svg';
import { User } from '../../../types/auth';
import { isBrowser } from '../../../utils/browser';
import Loading from '../../global/loading/loading';

const UpdateAccount: React.FC<{ setSuccess: any }> = ({ setSuccess }) => {
  const { i18n, currentUser, setCurrentUser } = useContext(GlobalContext);

  const [firstName, setFirstName] = useState({
    value: currentUser?.firstName,
    isValid: true,
    isTouched: false,
    valueChanged: false,
  });
  const [lastName, setLastName] = useState({
    value: currentUser?.lastName,
    isValid: true,
    isTouched: false,
    valueChanged: false,
  });
  const [error, setError] = useState(false);
  const [updateAccountError, setUpdateAccountError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const resetAllError = () => {
    setError(false);
    setUpdateAccountError(false);
  };

  const handleChange = (value: string, setFn: any, field: string) => {
    let isValid = false;
    let valueChanged = false;

    if (field === 'firstName' || field === 'lastName') {
      value = value.trim();
      isValid = value.length > 0 ? true : false;
      valueChanged = value !== currentUser?.[field];
    }

    setFn({ value, isValid, valueChanged });
  };

  const handleBlur = (setFn: any) => {
    setFn((prevValue: any) => {
      return { ...prevValue, isTouched: true };
    });
  };

  const checkValidityOnSubmit = (): boolean => {
    return firstName.isValid && lastName.isValid;
  };

  const checkFormValuesChanged = (): boolean => {
    return firstName.valueChanged || lastName.valueChanged;
  };

  const jwt = isBrowser ? window.localStorage.getItem('jwt') : null;

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // Already submitting
    if (submitting) {
      return;
    }

    resetAllError();

    if (!checkValidityOnSubmit()) return setError(true);
    if (checkValidityOnSubmit()) {
      try {
        setSubmitting(true);

        axios
          .put(
            `${process.env.GATSBY_STRAPI_URL}/api/users/me/account`,
            {
              firstName: firstName.value,
              lastName: lastName.value,
            },
            {
              headers: {
                Authorization: `Bearer ${jwt}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              resetAllError();
              setSuccess(true);
              setFirstName((prevValue: any) => {
                return { ...prevValue, valueChanged: false };
              });
              setLastName((prevValue: any) => {
                return { ...prevValue, valueChanged: false };
              });
              if (setCurrentUser)
                setCurrentUser((prevValue: User | undefined) => {
                  if (!prevValue) return undefined;
                  return {
                    ...prevValue,
                    firstName: firstName.value ?? '',
                    lastName: lastName.value ?? '',
                  };
                });
            }
          })
          .catch((err) => {
            resetAllError();
            setUpdateAccountError(true);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } catch (err) {
        resetAllError();
        setUpdateAccountError(true);
        setSubmitting(false);
      }
    }
  };

  return (
    <div className="section-container">
      <h3>{i18n?.t('personal_details')}</h3>
      <p className="account-message">
        {i18n?.t('personal_details.description')}
      </p>
      <form className="account-change-form" onSubmit={handleSubmit}>
        <div className="input-row">
          <label>{i18n?.t('form.labels.firstname')}</label>
          <input
            type="text"
            onChange={(e) => {
              handleChange(e.target.value, setFirstName, 'firstName');
            }}
            onBlur={() => {
              handleBlur(setFirstName);
            }}
            className={
              !firstName.isValid && firstName.isTouched ? 'red-border' : ''
            }
            defaultValue={firstName.value}
          ></input>
          {!firstName.isValid && firstName.isTouched && (
            <span className="warning">{i18n?.t('form.errors.firstname')}</span>
          )}
        </div>

        <div className="input-row">
          <label>{i18n?.t('form.labels.lastname')}</label>
          <input
            type="text"
            onChange={(e) => {
              handleChange(e.target.value, setLastName, 'lastName');
            }}
            onBlur={() => {
              handleBlur(setLastName);
            }}
            className={
              !lastName.isValid && lastName.isTouched ? 'red-border' : ''
            }
            defaultValue={lastName.value}
          ></input>
          {!lastName.isValid && lastName.isTouched && (
            <span className="warning">{i18n?.t('form.errors.lastname')}</span>
          )}
        </div>

        <div className="input-row lock-input-row">
          <label>{i18n?.t('form.labels.email2')}</label>
          <input type="text" defaultValue={currentUser?.email} disabled></input>
          <LockSVG className="lock" />
        </div>

        <span className="filled">
          <button
            className="submit-button btn btn-primary"
            type="submit"
            disabled={!checkValidityOnSubmit() || !checkFormValuesChanged()}
          >
            {submitting && <Loading type="btn" />}
            {!submitting && i18n?.t('update')}
          </button>
        </span>
      </form>
      {error && (
        <div className="error-container">
          <div className="icon-container">
            <WarningSVG />
          </div>
          <p>{i18n?.t('form.errors.all_fields')}</p>
        </div>
      )}
      {updateAccountError && (
        <div className="error-container">
          <div className="icon-container">
            <WarningSVG />
          </div>
          <p>{i18n?.t('form.errors.personal_details_update')}</p>
        </div>
      )}
    </div>
  );
};

export default UpdateAccount;
