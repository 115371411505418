import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';

import GlobalContext from '../../../context/global-context';
import { ReactComponent as GreenCheck } from '../../../images/check_green.svg';
import { ReactComponent as HidePWSVG } from '../../../images/password-reveal-hide.svg';
import { ReactComponent as ShowPwSVG } from '../../../images/password-reveal-show.svg';
import { ReactComponent as RedX } from '../../../images/x_red.svg';
import {
  NewPasswordInputState,
  PasswordValidators,
  PasswordValidatorStates,
} from '../../../types/auth';
import {
  ATLEAST_8_CHARACTERS,
  ATLEAST_ONE_LOWER_CASE,
  ATLEAST_ONE_NUMBER,
  ATLEAST_ONE_SPECIAL_CHAR,
  ATLEAST_ONE_UPPER_CASE,
} from '../../../utils/regexes';

const NewPasswordInput: React.FC<{
  label: string;
  type: string;
  newPasswordState: NewPasswordInputState;
  setNewPasswordState: Dispatch<SetStateAction<NewPasswordInputState>>;
}> = ({ label, type, newPasswordState, setNewPasswordState }) => {
  const { i18n } = useContext(GlobalContext);

  const [input, setInput] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [passwordValidators, setPasswordValidators] =
    useState<PasswordValidatorStates>({
      oneLower: false,
      oneUpper: false,
      oneNumber: false,
      oneSpecialChar: false,
      charMinimum: false,
    });
  const [isTouched, setIsTouched] = useState(false);
  const [isBlurred, setIsBlurred] = useState(false);

  const validators: PasswordValidators = {
    oneLower: ATLEAST_ONE_LOWER_CASE,
    oneUpper: ATLEAST_ONE_UPPER_CASE,
    oneNumber: ATLEAST_ONE_NUMBER,
    charMinimum: ATLEAST_8_CHARACTERS,
    oneSpecialChar: ATLEAST_ONE_SPECIAL_CHAR,
  };

  const validatorStrings = {
    oneLower: i18n?.t('form.password.one_lower'),
    oneUpper: i18n?.t('form.password.one_upper'),
    oneNumber: i18n?.t('form.password.one_number'),
    oneSpecialChar:
      i18n?.t('form.password.one_special_char') + ' (Ex: !@#$%*&+=)',
    charMinimum: i18n?.t('form.password.char_minimum'),
  };

  const handleChange = (str: string) => {
    if (type === 'confirmation')
      return setNewPasswordState((prevState) => {
        return { ...prevState, value: str };
      });

    if (!isTouched) setIsTouched(true);
    if (type !== 'confirmation') {
      setInput(str);
      setPasswordValidators((previousValue: any): any => {
        return Object.keys(previousValue).reduce((acc, validator) => {
          return {
            ...acc,
            [validator]:
              validators[validator as keyof PasswordValidators].test(str),
          };
        }, {});
      });
    }
  };

  useEffect(() => {
    if (passwordValidators && type !== 'confirmation')
      setNewPasswordState({
        value: input,
        isValid: Object.keys(passwordValidators).every(
          (validator) =>
            passwordValidators[validator as keyof PasswordValidatorStates] ===
            true
        ),
      });
  }, [passwordValidators]);

  return (
    <div>
      <label>{label}</label>
      <div className="password-row">
        <input
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          onBlur={() => {
            setIsBlurred(true);
          }}
          type={isPasswordVisible ? 'text' : 'password'}
          className={!newPasswordState.isValid && isBlurred ? 'red-border' : ''}
        ></input>
        {isPasswordVisible ? (
          <HidePWSVG
            className="hide"
            onClick={() => {
              setIsPasswordVisible((prevValue) => !prevValue);
            }}
          />
        ) : (
          <ShowPwSVG
            className="show"
            onClick={() => {
              setIsPasswordVisible((prevValue) => !prevValue);
            }}
          />
        )}
      </div>
      {isTouched && type !== 'confirmation' && (
        <div className="validator-display">
          {Object.keys(passwordValidators).map((validator: string, index) => {
            return (
              <div className="validator-row" key={`${index}-${validator}`}>
                <div className="icon-container">
                  {passwordValidators[
                    validator as keyof PasswordValidatorStates
                  ] ? (
                    <GreenCheck />
                  ) : (
                    <RedX />
                  )}
                </div>
                <span
                  className={`${
                    passwordValidators[
                      validator as keyof PasswordValidatorStates
                    ]
                      ? 'green'
                      : 'red'
                  }`}
                >
                  {validatorStrings[validator as keyof PasswordValidatorStates]}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NewPasswordInput;
