import React, { useEffect, useState } from 'react';

import { ReactComponent as Checkmark } from '../../../images/circle_check_outline.svg';
import { ReactComponent as CloseBtn } from '../../../images/close_small_current.svg';
import { ReactComponent as WarningSVG } from '../../../images/warning_circle_red.svg';

import './customToast.scss';

const CustomToast: React.FC<{
  children: any;
  toast?: any;
  setToast: any;
  error?: boolean;
}> = ({ children, toast, setToast, error }) => {
  const [closeTimeout, setCloseTimeout] = useState<NodeJS.Timeout | null>();

  const closeToast = () => {
    setToast(false);
  };

  useEffect(() => {
    if (closeTimeout) {
      clearTimeout(closeTimeout);
      setCloseTimeout(null);
    }
    if (toast)
      setCloseTimeout(
        setTimeout(() => {
          closeToast();
        }, 5000)
      );
  }, [toast]);

  return (
    <div
      className={`custom-toast ${error ? 'error' : ''} ${
        toast ? 'slide-up' : ''
      } ${toast === false ? 'slide-down' : ''}`}
    >
      {!error && <Checkmark className="check" />}
      {error && <WarningSVG className="error" />}
      {children}
      <CloseBtn className="close" onClick={closeToast} />
    </div>
  );
};

export default CustomToast;
